<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
		
				<tbody><tr>
					<td><div align="center"><font size="+2">Multispectral Image Database</font></div></td>
				</tr><tr>
						<td><center><img src="https://cave.cs.columbia.edu/old/databases/multispectral/images/teaser.png" width="800"></center></td></tr><tr><td><p>We present a database of multispectral images that were used to emulate the
GAP camera. The images are of a wide variety of real-world materials and
objects. We are making this database available to the research community.
Details of the database can be found in the following publication:</p></td></tr>
				<tr>
					<td><h3 id="Publications">Publications</h3>
						<blockquote>
				<b>"Generalized Assorted Pixel Camera: Post-Capture Control of Resolution, Dynamic Range and Spectrum," </b><br>F. Yasuma, T. Mitsunaga, D. Iso, and S.K. Nayar, <br>Technical Report, Department of Computer Science, Columbia University CUCS-061-08, <br>Nov. 2008.<br> [<a href="http://www1.cs.columbia.edu/CAVE/publications/./pdfs/Yasuma_TR08.pdf	" target="new">PDF</a>] [<a href="https://cave.cs.columbia.edu/reference?bid=229">bib</a>] [<a href="https://cave.cs.columbia.edu/copyright">©</a>] [<a href="/projects/categories/project?cid=Computational+Imaging&pid=Generalized+Assorted+Pixel+Camera" target="new">Project Page</a>]<br><br>
						</blockquote>
					</td>
				</tr>
			
					<tr>
						<td align="left">
							<h3 id="Image Capture Information">Image Capture Information</h3>
							<center><table border="1">
	<tbody><tr><td>Camera</td><td><a href="http://www.ccd.com/alta_u260.html">Cooled CCD camera (Apogee Alta U260)</a></td></tr>
	<tr><td>Resolution</td><td>512 x 512 pixel</td></tr>
	<tr><td>Filter</td><td><a href="http://www.cri-inc.com/products/varispec.asp">VariSpec liquid crystal tunable filter</a></td></tr>
	<tr><td>Illuminant</td><td>CIE Standard Illuminant D65</td></tr>
	<tr><td>Range of wevelength</td><td>400nm - 700nm</td></tr>
	<tr><td>Steps</td><td>10nm</td></tr>
	<tr><td>Number of band</td><td>31 band</td></tr>
	<tr><td>Focal length</td><td>f/1.4</td></tr>
	<tr><td>Focus</td><td>Fixed (focused using 550nm image)</td></tr>
	<tr><td>Image format</td><td>PNG (16bit) </td></tr>
</tbody></table></center>

<p>NOTE: These multispectral images represent the reflectances of the materials
in the scene. The reflectance spectrum at each pixel was computed from the
measured multispectrum image using calibrated estimates of the illumination
spectrum and camera spectral response. As a result, each multispectral image
should be taken as a close approximation, rather than exact measurement, of the
true reflectance of the scene.</p>

						</td>
					</tr>
			
					<tr>
						<td align="left">
							<h3 id="Database Organization">Database Organization</h3>
							<p>The database consists of 32 scenes, divided into 5 sections. Each scene has
an associated zip file. These zip files include full spectral resolution
reflectance data from 400nm to 700nm at 10nm steps (31 bands total).  Each band
is stored as a 16-bit grayscale PNG image. Image filenames are of the format
'object_ms_01.png', where the '01' at the end signifies that this is the first
image (captured at 400nm). Thus, '02' corresponds to 410nm, and so on, until
'31' for 700nm.</p>

<p>Each scene also contains a single representative color
image, displayed using sRGB values rendered under a neutral daylight illuminant
(D65).</p>
						</td>
					</tr>
			
					<tr>
						<td align="left">
							<h3 id="Access Instructions">Access Instructions</h3>
							<p>The database of 32 scenes is divided into 5 sections, as below. It is also available as a <a href="https://cave.cs.columbia.edu/old/databases/multispectral/zip/complete_ms_data.zip">single zip file</a> [387 MB].</p>
<center>
	<p><a href="/repository/Multispectral/Stuff"><img src="https://cave.cs.columbia.edu/old/databases/multispectral/stuff/thumbnail.png" height="120" border="0"></a><br><a href="/repository/Multispectral/Stuff">Stuff</a></p><br>
	<p><a href="/repository/Multispectral/SkinAndHair/"><img src="https://cave.cs.columbia.edu/old/databases/multispectral/skin_and_hair/thumbnail.png" height="120" border="0"></a><br><a href="/repository/Multispectral/SkinAndHair/">Skin and Hair</a></p><br>
	<p><a href="/repository/Multispectral/Paints/"><img src="https://cave.cs.columbia.edu/old/databases/multispectral/paints/thumbnail.png" height="120" border="0"></a><br><a href="/repository/Multispectral/Paints/">Paints</a></p><br>
	<p><a href="/repository/Multispectral/FoodAndDrinks/"><img src="https://cave.cs.columbia.edu/old/databases/multispectral/food_and_drinks/thumbnail.png" height="120" border="0"></a><br><a href="/repository/Multispectral/FoodAndDrinks/">Food and Drinks</a></p><br>
	<p><a href="/repository/Multispectral/RealandFake"><img src="https://cave.cs.columbia.edu/old/databases/multispectral/real_and_fake/thumbnail.png" height="120" border="0"></a><br><a href="/repository/Multispectral/RealandFake">Real and Fake</a></p><br>
</center>

						</td>
					</tr>
			
				<tr>
					<td align="left">
						<h3 id="Related Project">Related Project</h3>
						<blockquote>
				<p><a href="/projects/categories/project?cid=Computational+Imaging&pid=Generalized+Assorted+Pixel+Camera">Generalized Assorted Pixel Camera</a></p>

						</blockquote>
					</td>
				</tr>
				
			<tr><td height="20"></td></tr>
			</tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>